import React, { useEffect, useState } from 'react';
import { format } from 'date-fns'

import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import ListItemText from '@mui/material/ListItemText';

function OrariMesse (props) {

  const [messe, setMesse] = useState([]);

  useEffect(() => {
    loadMesseInfo(props.date)
    console.log(props.date)
  }, [props.date]);

  function day_of_the_month(d) { 
    return (d.getDate() < 10 ? '0' : '') + d.getDate();
  }


  const loadMesseInfo = (date) => {
    fetch(`data/messe.json`)
    .then((r) => r.json())
    .then((data) =>{
        const monthName = date.toLocaleString('en', { month: 'short' }).toLowerCase();
        const dayName = date.toLocaleString('en', { weekday: 'short' }).toLowerCase();
        const day = day_of_the_month(date)
        const d = day + monthName
        console.log(data)
        console.log(d)

        let messe = []
        // if day is sunday
        if( data.messe[d+dayName] ) {
          messe = data.messe[d+dayName]
        } else if( data.messe[d] ) {
          messe = data.messe[d]
        } else if( data.messe[day+"xx"] ) {
          messe = data.messe[day+"xx"]
        } else if( data.messe[dayName] ) {
          messe = data.messe[dayName]
        } else {
          messe = data.messe["feriali"]
        }
        setMesse(messe)
    })
  }

  const listItems = messe.map((m) =>
    <ListItem key={m.orario} disablePadding>
    <ListItemText primary={m.orario} />
    <ListItemText className="orari-messe-info" primary={m.info} />
    </ListItem> 
  );

  let title = "Orari Messe del " + format(props.date, "dd/MM/yy")
  let now = new Date()

  if ( format(props.date, "yyymmdd") === format(now, "yyymmdd") ) {
    title = "Orari Messe di OGGI " + format(props.date, "dd/MM/yy")
  } else if ( format(props.date, "yyymmdd") === format(now.setDate(now.getDate() + 1), "yyymmdd") ){
    title = "Orari Messe di DOMANI " + format(props.date, "dd/MM/yy")
  }

  return (
    <Box className="orari-messe-box" dense="true">
      <div className="box-header">
        <Typography variant="h6" gutterBottom>
          {title}
        </Typography>
      </div>
      <nav aria-label="main mailbox folders">
        <List>
           {listItems}
        </List>
      </nav>
    </Box>
  );
};
  
export default OrariMesse;